import {
  createBrowserEvent,
  createGenericEvent,
  setupPageVisitListeners,
  setupSessionListeners
} from 'modules/analytics/core'
import { parsePublicKey } from 'modules/crypto/box'
import { sendEvent } from './send'
import type { Config } from './types'

function readConfig(): Config | null {
  try {
    const selfElement = document.getElementById('chiffre:analytics')
    const jsonConfig = JSON.parse(
      document.getElementById('chiffre:analytics-config')?.innerText || '{}'
    )
    const projectID = selfElement?.dataset.chiffreProjectId
    const serverURL =
      selfElement?.dataset.chiffreServerUrl ?? process.env.APP_URL
    const pushURL = projectID
      ? `${serverURL}/event/${projectID}`
      : jsonConfig.pushURL
    const publicKey =
      selfElement?.dataset.chiffrePublicKey ?? jsonConfig.publicKey
    const ignorePaths = selfElement?.dataset.chiffreIgnorePaths?.split(',')
    if (!pushURL) {
      throw new Error('Missing pushURL')
    }
    if (!publicKey) {
      throw new Error('Missing public key')
    }
    return {
      publicKey: parsePublicKey(publicKey),
      pushURL,
      ignorePaths
    }
  } catch (error) {
    console.error(
      '[Chiffre] Failed to load Chiffre analytics configuration:',
      error
    )
    return null
  }
}

export function setup() {
  window.chiffre = {
    sendNumber: () => {},
    sendNumbers: () => {},
    sendString: () => {},
    sendStrings: () => {}
  }
  const config = readConfig()
  if (!config) {
    return
  }
  if (navigator.doNotTrack === '1') {
    // With DoNotTrack, we send a single event for page views, without
    // any session tracking or other visitor information.
    const dntEvent = createBrowserEvent('session:dnt', null)
    sendEvent(dntEvent, config)
  } else {
    setupSessionListeners(event => sendEvent(event, config))
    setupPageVisitListeners(event => sendEvent(event, config))
  }
  window.chiffre.sendNumber = data => {
    const event = createGenericEvent('generic:number', data)
    sendEvent(event, config)
  }
  window.chiffre.sendNumbers = data => {
    const event = createGenericEvent('generic:numbers', data)
    sendEvent(event, config)
  }
  window.chiffre.sendString = data => {
    const event = createGenericEvent('generic:string', data)
    sendEvent(event, config)
  }
  window.chiffre.sendStrings = data => {
    const event = createGenericEvent('generic:strings', data)
    sendEvent(event, config)
  }
}
