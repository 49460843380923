import b64 from '@47ng/codec/dist/b64'
import { randomBytes } from 'tweetnacl'

const MARKER_KEY = '__p__'
const MARKER_VAL = 'padding'

const unpaddingRegexp = new RegExp(
  `,"${MARKER_KEY}([\\w-]+)":"${MARKER_VAL}"}$`
)

export function padJsonString(json: string, maxPadding = 32) {
  const paddingLength = maxPadding - (json.length % maxPadding)
  const padding = b64
    .encode(randomBytes(Math.max(paddingLength, 1)))
    .slice(0, paddingLength)
  return json.replace(/}$/, `,"${MARKER_KEY}${padding}":"${MARKER_VAL}"}`)
}

export function unpadJsonString(json: string) {
  return json.replace(unpaddingRegexp, '}')
}
