/**
 * The version is not actually read from the environment at runtime.
 *
 * It is replaced by esbuild's `define` option:
 * https://esbuild.github.io/api/#define
 *
 * The release tag is generated by the continuous deployment pipeline
 * (see <repoRoot>/.github/workflows/ci-cd.yml, in step release-tag)
 *
 * It is then sent to Clever Cloud as the RELEASE_TAG env var.
 * When Clever Cloud builds the app, esbuild will resolve in-place
 * the environment variable value.
 *
 * Local builds will fallback to `local` as a version value.
 */

export const version = process.env.RELEASE_TAG
