import { Event } from 'modules/analytics/core'
import { encryptString } from 'modules/crypto/box'
import { padJsonString } from 'modules/crypto/padding'
import type { Config } from './types'
import { version } from './version'

function buildURL(config: Config, perf: number, xhr: string) {
  const query = 'v=' + version + '&xhr=' + xhr + '&perf=' + perf.toFixed()
  return `${config.pushURL}?${query}`
}

export function sendEvent(event: Event<any, any>, config: Config) {
  const tick = performance.now()
  if (
    window.location.hostname.match(/^(localhost|127\.0\.0\.1)$/) &&
    window.localStorage?.getItem('chiffre:allowLocalhost') !== 'true'
  ) {
    // Don't send events from localhost
    return
  }
  if (Array.isArray(config.ignorePaths)) {
    for (const path of config.ignorePaths) {
      if (window.location.pathname.startsWith(path)) {
        return // Do not send event
      }
    }
  }
  const json = JSON.stringify(event)
  const maxPadding = 32 // Add at most (maxPadding / 2) random characters
  const padded = padJsonString(json, maxPadding)
  const payload = encryptString(padded, config.publicKey)
  const tock = performance.now()
  const perf = Math.round(tock - tick)
  if (window.localStorage?.getItem('chiffre:debug') === 'true') {
    console.dir({
      event,
      payload,
      perf,
      version
    })
  }
  if (window.localStorage?.getItem('chiffre:no-send') === 'true') {
    console.info('[Chiffre] Not sending message', {
      payload,
      perf
    })
    return false
  }

  const fetchUrl = buildURL(config, perf, 'fetch')
  const beaconUrl = buildURL(config, perf, 'beacon')
  const imageUrl = buildURL(config, perf, 'img')

  if (sendViaFetch(fetchUrl, payload)) {
    return true
  }
  if (sendViaBeacon(beaconUrl, payload)) {
    return true
  }
  return sendViaImageGet(imageUrl, payload)
}

function sendViaFetch(url: string, payload: string) {
  if (!('fetch' in window)) {
    return false
  }
  fetch(url, {
    method: 'POST',
    body: payload,
    /**
     * We use the origin for spam prevention, to make sure traffic on your
     * project actually comes from your website.
     */
    referrerPolicy: 'origin',
    referrer: window.location.origin,
    credentials: 'omit',
    cache: 'no-store',
    mode: 'no-cors',
    keepalive: true,
    headers: {
      'content-type': 'text/plain'
    }
  })
  return true
}

function sendViaBeacon(url: string, payload: string) {
  if (
    typeof navigator.sendBeacon === 'function' &&
    navigator.sendBeacon(url, payload)
  ) {
    return true
  }
  return false
}

function sendViaImageGet(url: string, payload: string) {
  const img = new Image()
  img.src = `${url}&payload=${payload}`
  return true
}
